import authenticationService from "./authentication.service";
import axios from "axios";
import API_URL from "../_helpers/api_url";

class TokenService {
  getLocalRefreshToken() {
    return JSON.parse(localStorage.getItem("refreshToken"));
  }

  getLocalAccessToken() {
    return JSON.parse(localStorage.getItem("accessToken"));
  }

  getFirstName(){
    return JSON.parse(localStorage.getItem("firstName"));
  }
  
  getLastName(){
    return  JSON.parse(localStorage.getItem("lastName"));
  }

  getUserId(){
    return JSON.parse(localStorage.getItem("id"));
  }

  getRole(){
    return JSON.parse(localStorage.getItem("role"))
  }


  getPreviousAuthTokenResponse() {
    return JSON.parse(localStorage.getItem("previousAuthTokenResponse"));
  }


  saveApiTokenResponse(token) {
    authenticationService.updateToken(JSON.stringify(token));
    localStorage.setItem("previousAuthTokenResponse", JSON.stringify(token));
    localStorage.setItem("accessToken", JSON.stringify(token.accessToken) );
    localStorage.setItem("refreshToken", JSON.stringify(token.refreshToken) );
    localStorage.setItem("role",JSON.stringify(token.role));
    localStorage.setItem("firstName",JSON.stringify(token.firstName));
    localStorage.setItem("lastName",JSON.stringify(token.lastName));
    localStorage.setItem("id",JSON.stringify(token.id));
  }

  async updateRefreshToken(){
    await axios.post(API_URL + "auth/refresh", {

      accessToken : this.getLocalAccessToken(),
      refreshToken: this.getLocalRefreshToken(),
      
    },{
      headers: {
        "Authorization" : "Bearer " + this.getLocalRefreshToken(),
        "Content-Type": "application/json"
      }
    }).then(success =>{
      let oldToken = this.getPreviousAuthTokenResponse()
      let data = success.data
      oldToken.accessToken = data.accessToken
      oldToken.refreshToken = data.refreshToken
      console.log(data)
      this.saveApiTokenResponse(oldToken)
    } )
  }

  removeUser() {
    localStorage.removeItem("previousAuthTokenResponse");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("role");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("id");
  }
}

// eslint-disable-next-line
export default new TokenService();