export default function Contact() {
  return (
    <div>
      <div  className="container-fluid">
          <div  className="row">
              <div  className="col-lg-2"></div>
                  <div  className="col-sm-12 col-lg-8 p-5 pb-3 pt-lg-5 pt-2">
                    <h1>Contact</h1>
                    <h3>healthifyplaceholder@domain.com</h3>
                    <p>In future this would have a proper webform.</p>
                  </div>
             </div>
      </div>
    </div>
  );
}
