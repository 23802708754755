import { Link } from "react-router-dom";

export default function NoMatch() {
  return (
    <div>
      <div className="container-fluid">
          <div className="row">
              <div  className="col-lg-2"></div>
                  <div className="col-sm-12 col-lg-8 p-5 pb-3 pt-lg-5 pt-2">
                    <h1>404! Page not found.</h1>
                    <h3>We're sorry, but the page you've requested cannot be found. We have alerted the team to try and fix this.</h3>
                    <Link to="/" style={{textDecoration: 'none'}}><button  className="text-black btn btn-lg btn-info">Return home?</button></ Link>
                  </div>
             </div>
      </div>
    </div>
  );
}
