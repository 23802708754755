import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip } from "chart.js";
import "./MoodSleep"; 

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip);

export default function SleepGraph({ sleepData, weekDates, onPreviousWeek, onNextWeek }) {
  const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];


  const dataPoints = weekDates.map((date) => {
    const entry = sleepData.find((entry) => entry.date === date);
    return entry ? entry.hours : null;
  });


  let previousColor = "#4CAF50"; 
  const segmentColors = dataPoints.map((point, index) => {
    if (index === dataPoints.length - 1 || dataPoints[index + 1] === null) {
      return previousColor;
    }
    if (dataPoints[index + 1] > point) {
      previousColor = "#4CAF50"; 
    } else if (dataPoints[index + 1] < point) {
      previousColor = "#FF5722"; 
    }
    return previousColor;
  });

  const borderWidth = 2; 

  const data = {
    labels: weekdays,
    datasets: [
      {
        label: "Hours of Sleep",
        data: dataPoints,
        backgroundColor: "rgba(171, 121, 166, 0.2)",
        borderColor: segmentColors, 
        borderWidth: borderWidth, 
        pointBackgroundColor: segmentColors, 
        pointRadius: dataPoints.map((point) => (point === null ? 0 : 5)), 
        segment: {
          borderColor: (ctx) => (ctx.p1DataIndex && dataPoints[ctx.p1DataIndex] < dataPoints[ctx.p0DataIndex] ? "#FF5722" : "#4CAF50"),
        },
        tension: 0, 
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 24,
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltipItem) => `Hours slept: ${tooltipItem.raw}`,
        },
      },
    },
  };


  return (
    <div
      style={{
        width: "100%",
        maxWidth: "600px",
        height: "350px",
        margin: "0 auto",
        marginBottom: "40px", 
        border: "3px solid #A17D4C",
        borderRadius: "8px",
        padding: "16px",
        boxSizing: "border-box",
        backgroundColor: "rgba(171, 121, 166, 0.2)",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>
        <button onClick={onPreviousWeek} className="week-navigation-button">Prev. Week</button>
        <h3 style={{ margin: 0, textAlign: "center", flexGrow: 1 }}>Your Sleep Statistics</h3>
        <button onClick={onNextWeek} className="week-navigation-button">Next Week</button>
      </div>
      <Line data={data} options={options} />
    </div>
  );
}
