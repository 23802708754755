import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../index.css";

export default function AdminTaskSuccess() {
    const navigate = useNavigate();

    return (
        <div className="adminpage-container">
            <nav className="adminpage-navbar">
                <div className="adminpage-navbar-content">
                    <h1>Task Created Successfully!</h1>
                </div>
            </nav>
            <div className="adminpage-main-content">
                <div className="adminpage-info-box">
                    <h2>Your task has been created.</h2>
                    <p>Assigned users can now access this task.</p>
                    <button 
                        className="adminpage-submit-button" 
                        onClick={() => navigate('/admin')}
                    >
                        Back to Admin Page
                    </button>
                </div>
            </div>
        </div>
    );
}
