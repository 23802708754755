import React, { useState } from "react";
import "./MoodSleep"; // Import CSS for modal styling

export default function MoodModal({ isOpen, onClose }) {
  const [selectedMood, setSelectedMood] = useState(null); // State to track the selected mood

  // Array of mood options
  const moods = [
    { id: 1, label: "Happy", emoji: "😊" },
    { id: 2, label: "Ok", emoji: "🙂" },
    { id: 3, label: "Calm", emoji: "😌" },
    { id: 4, label: "Neutral", emoji: "😐" },
    { id: 5, label: "Tired", emoji: "😴" },
    { id: 6, label: "Nervous", emoji: "😟" },
    { id: 7, label: "Sad", emoji: "😢" },
    { id: 8, label: "Woozy", emoji: "😵" },
  ];

  // Function to handle mood selection
  const handleMoodClick = (id) => {
    setSelectedMood(id); // Set the selected mood's id
  };

  // Close modal when done
  const handleDone = () => {
    console.log("Selected Mood:", selectedMood); // Log the selected mood (you can replace this with actual logic)
    onClose(); // Close the modal
  };

  if (!isOpen) return null; // Don't render if modal is not open

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>What smile describes your mood best?</h2>
        <div className="mood-grid">
          {moods.map((mood) => (
            <button
              key={mood.id}
              className={`mood-button ${
                selectedMood === mood.id ? "selected" : ""
              }`}
              onClick={() => handleMoodClick(mood.id)}
            >
              <div className="mood-emoji">{mood.emoji}</div>
              <div className="mood-label">{mood.label}</div>
            </button>
          ))}
        </div>
        <button className="done-button" onClick={handleDone}>
          Done
        </button>
        <button className="modal-close" onClick={onClose}>
          X
        </button>
      </div>
    </div>
  );
}
