import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../index.css";

export default function AdminCreateTask() {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isGlobalTask, setIsGlobalTask] = useState(false);
    const [taskTitle, setTaskTitle] = useState('');
    const [taskDescription, setTaskDescription] = useState('');
    const [taskImage, setTaskImage] = useState('');
    const [taskPoints, setTaskPoints] = useState('');

    const navigate = useNavigate();

    const users = [
        'John Smith', "Adam Smith", "Another User", "Another User 2",
        "Bill Bill", "Humphry Docherty", "Sylvia Lastname", 
        "Gerald Small", "Tom Jack", "Selena Gomez", "Kyle Bowden", 
        "Another Name Again", "David David", "David Smith", 
        "David Daniel", "David Phill" 
    ];

    const filteredUsers = users.filter(user =>
        user.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const toggleUserSelection = (user) => {
        if (selectedUsers.includes(user)) {
            setSelectedUsers(selectedUsers.filter(u => u !== user));
        } else {
            setSelectedUsers([...selectedUsers, user]);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const taskData = {
            assignedTo: isGlobalTask ? 'Everyone' : selectedUsers,
            title: taskTitle,
            description: taskDescription,
            image: taskImage,
            points: taskPoints,
        };
        console.log("Task Created:", taskData);
        navigate("/admin/create-task/success");
    };

    return (
        <div className="adminpage-container">
            <nav className="adminpage-navbar">
                <div className="adminpage-navbar-content">
                    <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="Profile" className="adminpage-profile-picture"/>
                    <h1>Create Task</h1>
                    <button 
                        className="adminpage-back-button" 
                        onClick={() => navigate('/admin')}
                    >
                        Back
                    </button>
                </div>
            </nav>
            <div className="adminpage-main-content">
                <div className="adminpage-left">
                    <form className="adminpage-task-form" onSubmit={handleSubmit}>
                        <div className="adminpage-info-box">
                            <h2>Task Details</h2>
                            <input 
                                type="text" 
                                placeholder="Task Title" 
                                value={taskTitle} 
                                onChange={e => setTaskTitle(e.target.value)}
                                required
                                className="adminpage-input"
                            />
                            <textarea 
                                placeholder="Task Description" 
                                value={taskDescription} 
                                onChange={e => setTaskDescription(e.target.value)}
                                required
                                className="adminpage-input"
                            ></textarea>
                            <input 
                                type="text" 
                                placeholder="Image URL" 
                                value={taskImage} 
                                onChange={e => setTaskImage(e.target.value)}
                                className="adminpage-input"
                            />
                            <input 
                                type="number" 
                                placeholder="Points for completing task" 
                                value={taskPoints} 
                                onChange={e => setTaskPoints(e.target.value)}
                                required
                                min="0"
                                className="adminpage-input"
                            />
                            <button type="submit" className="adminpage-submit-button">Create Task</button>
                        </div>
                    </form>
                </div>
                <div className="adminpage-right">
                    <div className="adminpage-info-box">
                        <h2>Assign Task</h2>
                        <div className="task-assignment-options">
                            <div className="assign-global-checkbox">
                                <input 
                                    type="checkbox" 
                                    id="global-task-checkbox" 
                                    checked={isGlobalTask} 
                                    onChange={() => setIsGlobalTask(!isGlobalTask)}
                                />
                                <label htmlFor="global-task-checkbox">Assign to everyone</label>
                            </div>
                            {!isGlobalTask && (
                                <div className="adminpage-search-box">
                                    <input 
                                        type="text" 
                                        placeholder="Search users..." 
                                        value={searchTerm} 
                                        onChange={e => setSearchTerm(e.target.value)} 
                                    />
                                    <div className="taskpage-user-list-box">
                                        <div className="adminpage-user-list">
                                            {filteredUsers.map((user, index) => (
                                                <div 
                                                    key={index} 
                                                    className={`adminpage-user-box ${selectedUsers.includes(user) ? "selected" : ""}`}
                                                    onClick={() => toggleUserSelection(user)}
                                                >
                                                    {user}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
