import { Link } from "react-router-dom";
import { useDarkMode } from "../navigation_components/DarkModeContext"; 

export default function CommunityHome() {
  const { darkMode } = useDarkMode(); 

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-sm-12 col-lg-8 p-5 pb-3 pt-lg-5 pt-2">
            <div className="clearfix mb-3">

              <button
                className={`btn shadow rounded float-end add-post-button ${darkMode ? "dark-mode" : ""}`}
              >
                <h2>
                  <Link to="" style={{ textDecoration: "none", color: "inherit" }}>Add Post</Link>
                </h2>
              </button>
              <h1 className="float-start">Community</h1>
            </div>

            <div className="m-2">
              <Link style={{ textDecoration: "none", color: darkMode ? "#f1f1f1" : "black" }} to="/community/example">
                <div className={`rounded p-4 pb-1 communitySection ${darkMode ? "communitySection-dark-mode" : ""}`}>
                  <h4>Rules</h4>
                  <p>Please read our rules before contributing</p>
                </div>
              </Link>
            </div>

            <div className="m-2">
              <h5>Forum</h5>

              <Link style={{ textDecoration: "none", color: darkMode ? "#f1f1f1" : "black" }}>
                <div className={`rounded-top p-4 pb-1 communitySection ${darkMode ? "communitySection-dark-mode" : ""}`}>
                  <h4>Recipes</h4>
                  <p>Share your recipes and learn from others</p>
                  <hr />
                  <p>Latest Post: Beef Stroganoff by <i>CoolPenguin123</i> | today at 20:24</p>
                </div>
              </Link>

              <Link style={{ textDecoration: "none", color: darkMode ? "#f1f1f1" : "black" }}>
                <div className={`p-4 pb-1 communitySection ${darkMode ? "communitySection-dark-mode" : ""}`}>
                  <h4>Fitness</h4>
                  <p>Share how you keep active</p>
                  <hr />
                  <p>Latest Post: Aberdeenshire Walks by <i>BanchoryLad</i> | 4 November</p>
                </div>
              </Link>

              <Link style={{ textDecoration: "none", color: darkMode ? "#f1f1f1" : "black" }}>
                <div className={`p-4 pb-1 communitySection ${darkMode ? "communitySection-dark-mode" : ""}`}>
                  <h4>Support</h4>
                  <p>In need of support? Ask here.</p>
                  <hr />
                  <p>Latest Post: Lack of Motivation by <i>costantan</i> | yesterday at 12:05</p>
                </div>
              </Link>

              <Link style={{ textDecoration: "none", color: darkMode ? "#f1f1f1" : "black" }}>
                <div className={`rounded-bottom p-4 pb-1 communitySection ${darkMode ? "communitySection-dark-mode" : ""}`}>
                  <h4>General Discussion</h4>
                  <p>Yap. Relax.</p>
                  <hr />
                  <p>Latest Post: Edinburgh Retail by <i>ScottishPerson7</i> | never</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
