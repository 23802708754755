import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../index.css";

export default function Admin() {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const navigate = useNavigate(); // For navigation
    const users = [
        'John Smith', "Adam Smith", "Another User", "Another User 2", "Bill Bill",
        "Humphry Docherty", "Sylvia Lastname", "Gerald Small", "Tom Jack", "Selena Gomez",
        "Kyle Bowden", "Another Name Again", "David David", "David Smith", "David Daniel", "David Phill" 
    ];

    const filteredUsers = users.filter(user => 
        user.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="adminpage-container">
            <nav className="adminpage-navbar">
                <div className="adminpage-navbar-content">
                    <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="Profile" className="adminpage-profile-picture"/>
                    <h1>Welcome back, USER</h1>
                </div>
                <button 
                    className="adminpage-create-task-button"
                    onClick={() => navigate('/admin/create-task')} // Navigate on button click
                >
                    Create Task
                </button>
            </nav>
            <div className="adminpage-main-content">
                <div className="adminpage-info-box">
                    <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="Profile" className="adminpage-info-picture"/>
                    <h2>Information for {selectedUser || "Select a User"}</h2> 
                    <hr className="adminpage-info-divider" />
                    <div className="adminpage-info-details">
                        <div className="adminpage-info-column">
                            <p>Age: X</p>
                            <p>Height: X</p>
                            <p>Sex: X</p>
                        </div>
                        <div className="adminpage-info-column">
                            <p>Weight: X</p>
                            <p>Days in Program: X</p>
                            <p>Condition: X</p>
                        </div>
                    </div>
                </div>
            </div>
            <aside className="adminpage-sidebar">
                <div className="adminpage-search-box">
                    <input 
                        type="text" 
                        placeholder="Search users..." 
                        value={searchTerm} 
                        onChange={e => setSearchTerm(e.target.value)} 
                    />
                </div>
                <div className="adminpage-user-list-box">
                    <div className="adminpage-user-list">
                        {filteredUsers.map((user, index) => (
                            <div 
                                key={index} 
                                className="adminpage-user-box"
                                onClick={() => setSelectedUser(user)}
                            >
                                {user}
                            </div>
                        ))}
                    </div>
                </div>
            </aside>
        </div>
    );
}
