import { Link } from "react-router-dom";
import authenticationService from "../services/authentication.service";
import { useDarkMode } from "../navigation_components/DarkModeContext"; 

function loginHome() {
  if (authenticationService.currentUserValue) {
    return (
      <Link to="/account">
        <button className="btn btn-lg btn-success mb-lg-5">
          Account Home
        </button>
      </Link>
    );
  } else {
    return (             
      <div>
        <Link to="register">
          <button className={"btn btn-lg btn-primary mb-lg-5"} >Register</button>
        </Link>
        <Link to ="login">
          <button className={"btn btn-lg btn-secondary ms-2 mb-lg-5"}>Log In</button>
        </Link>
       
        
      </div> 
      
    )
  }
}

export default function Home() {
  const { darkMode } = useDarkMode(); 

  return (
    <div className={darkMode ? "dark-mode" : ""}>
      <div className="jumbotron-fluid">
        <div
          className="container p-5 pb-3 pt-lg-5 pt-2"
          style={{
            backgroundColor: darkMode ? "#65295f" : "white",
            color: darkMode ? "#ffffff" : "#000000",
            borderBottom: "solid 4px #65295f"
          }}
        >
          <h1>Healthify</h1>
          <p className="lead">A new approach to lifestyle medicine</p>
          <hr />
          {loginHome()}
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-sm-12 col-lg-8 p-5 pb-3 pt-2">
            <div className="container-fluid">
              <div className="row">
              <h1 style={{ color: darkMode ? "#ffffff" : "#000000" }}>More about Healthify</h1>
              <hr />

                <div className="col-lg-8">

                  <h2 style={{ color: darkMode ? "#ffffff" : "#000000" }}>
                    Personalized Health and Wellness Profiles
                  </h2>
                  <ul style={{ color: darkMode ? "#f1f1f1" : "#000000" }}>
                    <li>Customized user profiles to track nutrition, exercise, mood, and more</li>
                    <li>Visual progress comparisons with before-and-after images</li>
                    <li>Integration with Dr. Monika's Google Calendar for appointments</li>
                    <li>Multiselect chronic condition tracking</li>
                  </ul>
                  <h2 style={{ color: darkMode ? "#ffffff" : "#000000" }}>
                    Example Header Two
                  </h2>
                  <ul style={{ color: darkMode ? "#f1f1f1" : "#000000" }}>
                    <li>Placeholder bullet point</li>
                  </ul>
                </div>
                <div className="col-lg-4" >
                   <h2 style={{ color: darkMode ? "#ffffff" : "#000000" }} >Who's behind Healthify?</h2>
                      <div className={"bg-white rounded"}>
                        <img src={process.env.PUBLIC_URL + 'monika_holding_food.webp'} style={{width:"100%"}} className={"rounded-top"} alt="Dr Monika Gostic holding food."></img>
                        <h3 className={"text-center p-2"}>Dr. Monika Gostic</h3>
                      </div> 
                </div>
              </div>
            </div>
            <hr />
            <h1 style={{ color: darkMode ? "#ffffff" : "#000000" }}>Why us?</h1>
            <p style={{ color: darkMode ? "#f1f1f1" : "#000000" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...
            </p>
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
    </div>
  );
}
