import { useLocation, useNavigate } from "react-router-dom";
import "../index.css"; 
import { useDarkMode } from "../navigation_components/DarkModeContext"; 
import { useEffect, useState } from "react";
import api from '../services/api';


export default function TaskSuccessful() {
  const location = useLocation();
  const navigate = useNavigate();
  const { darkMode } = useDarkMode(); 
  const taskData = location.state;
  const [points, setPoints] = useState(null);

  useEffect(() => {
    //Run async function in use effect. 
    const fetchData = async () => {
        await api.get("user/points",)
        .then(success => {
            setPoints(success.data);
        },
        error => {
            console.log("Unable to load points");
        });
    }
    fetchData();  
  },[]);

  if (!taskData) {
    navigate('/account');
    return null;
  }

  const handleClaimClick = () => {
    navigate('/account');
  };



  return (
    <div className="container-fluid d-flex justify-content-center align-items-center vh-100">
      <div className="col-lg-8 p-5 text-center">
      <h2>{taskData.taskName} - Completed successfully!</h2>
        <h2>You gained 10 points!</h2>
        <p className="placeholder-wave">Your new balance is {points? <b>{points + 10}</b>: <span className="placeholder col-12"></span>} points</p>
        <button
          onClick={handleClaimClick}
          className={`custom-button ${darkMode ? "dark-mode-button" : "classic-button"}`}
        >
          Claim
        </button>
      </div>
    </div>
    
  );
}
