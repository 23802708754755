import React, { useState, useEffect } from "react";
import "../index.css";
import api from "../services/api";

const getTimeForPicker = () => {
    const now = new Date();
    return now.toISOString().slice(11, 16); // formats to "yyyy-MM-ddTHH:mm"
    
  };

  const getDateForPicker = () => {
    const now = new Date();
    return now.toISOString().slice(0, 10); // formats to "yyyy-MM-ddTHH:mm"
    
  };

const default_state = {
    "category":"None",
    "food_name":"",
    "quantity":1,
    "description":"",
    "hour_min": getTimeForPicker(),
    "day": getDateForPicker()
}

function FoodPageRadioButtonFoodWater() {
    const [selectedOption, setSelectedOption] = useState("Food");
    const [foodObject, setFoodObject] = useState(default_state);
    const [foodFormState, setFoodFormState] = useState({ isLoading : true,isSubmitting: false})
    const [foodHistory, setFoodHistory] = useState([]);
    const [waterHistory, setWaterHistory] = useState([]); // State for water history
    const [waterCups, setWaterCups] = useState(0);
    const [waterLiters, setWaterLiters] = useState(0);
    const [watType, setWatType] = useState(false);
    const [dateDisplay, setdateDisplay] = useState(getDateForPicker());
    const handleOptionChange = (event) => {
        const errorMessages = {}
        setErrors(errorMessages)
        setSelectedOption(event.target.value);
    };
    

    const incrementDay = () => {
        var parts = dateDisplay.split("-");
        console.log(parts)
        console.log(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10))
        const newday = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
        newday.setDate(newday.getDate() + 1)
        if (newday < new Date()){
            setFoodObject({...foodObject, time_period: newday.toISOString().slice(0, 10)})
            setdateDisplay(newday.toISOString().slice(0, 10))
        }
    }
                
      const decrementDay = () => {
        var parts = dateDisplay.split("-");
        console.log(parts)
        console.log(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10))
        const newday = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
        newday.setDate(newday.getDate() - 1)
        setFoodObject({...foodObject, time_period: newday.toISOString().slice(0, 10)})
        setdateDisplay(newday.toISOString().slice(0, 10))
        
      }

    const inverseWat = () => {
        setWatType(!watType)
        setWaterLiters(0)
        setWaterCups(0)
    }


    async function handleAddWater() {

        const errorMessages = {}

        if (waterCups){
            if (waterCups < 1){
              errorMessages.waterCup = "Cups cannot be negative"
            }
        }

        if (waterLiters) {
            if (waterLiters < 0){
                errorMessages.waterLit = "Litres cannot be negative"
            }
        }

        if (waterLiters && waterCups) {
            errorMessages.waterBoth = "Please enter liters or cups, not both"
        }

        if (!waterLiters && !waterCups) {
            errorMessages.waterBoth = "No values entered"
        }

        if (Object.keys(errorMessages).length === 0){
            const newWaterEntry = {
                quantity: waterCups ? parseInt(`${waterCups}` * 250) : parseInt(`${waterLiters}` * 1000),
                date: dateDisplay + "T00:00:00"
            };
                await api.post("account/water/add",newWaterEntry).then(success => {
                    setWaterHistory([...waterHistory, newWaterEntry]);  // Add new entry to water history
                    setWaterCups(0);  // Clear input fields
                    setWaterLiters(0);
                }, error =>
                {
                    console.log("error" + error);
                    errorMessages.backend = "A server error has occured."
                    setErrors(errorMessages)
                });
        } else {
            setErrors(errorMessages)
        }
        
    };

    const [errorMessages, setErrors] = useState({});

    async function postFoodUpdate(){
        const errorMessages = {}

        if (foodObject.food_name === ""){
            errorMessages.emptyFood = "Food cannot be empty"
        }

        if (foodObject.food_name.toString().length  > 20){
            errorMessages.tooLongFood = "Food name too long"
        }
        if (foodObject.description){
            if (foodObject.description.toString().length  > 175){
                errorMessages.tooLongDesc = "Food description too long"
            }
        }
    

        if (Object.keys(errorMessages).length === 0){
            foodObject.time_period = dateDisplay + "T" +foodObject.hour_min + ":00"

            setFoodFormState({...foodFormState, isSubmitting: true}); 
                await api.post("account/food/add",foodObject).then(success => {
                let foodCopy = {...foodObject, timestamp: new Date().toISOString()};
                setFoodObject(default_state);
                setFoodHistory([...foodHistory,foodCopy]);

            }, error =>{
                errorMessages.backend = "A server error has occured."
                setErrors(errorMessages)
            });
            } else {
                  setErrors(errorMessages)
            }
            setFoodFormState({...foodFormState, isSubmitting: false}); 
               
            }


    useEffect(() => {
        //Run async function in use effect. 
        const fetchData = async () => {
            const today = dateDisplay;

            await api.get("account/food/list", {params: { from_date: today, to_date: today }})
            .then(success => {
                setFoodHistory(success.data);
            },
            error => {
                //Do some processing here.
                console.log("error occured. ");
            });
            await api.get("account/water/list", {params: { from_date: today, to_date: today }}).then(success => {
                console.log("got data", success.data)
                setWaterHistory(success.data) 
            }, 
            error =>{
                console.log("error occured while loading water history " );
            })
        }
        setFoodFormState({...foodFormState, isLoading:false});

        fetchData();
        
    // eslint-disable-next-line    
    },[dateDisplay]);

    return (
        <div>
            <div className="FoodPage-div-width">
                <div className="mb-3 justify-content text-center FoodPage-date p-3">
                <h5><button className="btn p-1 me-2" onClick={decrementDay}>        
                    <i class="bi bi-arrow-bar-left"></i></button><b>{dateDisplay}</b><button className="btn p-1 ms-2" onClick={incrementDay}><i class="bi bi-arrow-bar-right"></i></button></h5>
                </div>
            
                            
                <label className={`btn FoodPage-food-water-switcher FoodPage-rounded-corners-left FoodPage-width align-self-center FoodPage-hover ${selectedOption === "Food" ? "selected highlight" : ""}`}>
                    <input
                        type="radio"
                        value="Food"
                        checked={selectedOption === "Food"}
                        onChange={handleOptionChange}
                    />
                    <h2 className="FoodPage-center">Food</h2>
                </label>
                <label className={`btn FoodPage-food-water-switcher FoodPage-rounded-corners-right FoodPage-width align-self-center FoodPage-hover ${selectedOption === "Water" ? "selected highlight" : ""}`}>
                    <input
                        type="radio"
                        value="Water"
                        checked={selectedOption === "Water"}
                        onChange={handleOptionChange}
                    />
                    <h2 className="FoodPage-center">Water</h2>
                </label>
            </div>

            <br />

            {/* Conditionally rendered sections */}
            {selectedOption === "Food" && (
                <div>
                    <div className="row">
                        <div className="col-lg-6">
                            {/* meals dropdown */}

                            <h2 className="FoodPage-left">When did you eat:</h2>

                            <input
                                type="time"
                                id="foodpage_dropdown"
                                name="food_time"
                                className={"p-3"}

                                value={foodObject.hour_min}
                                onChange={(e) => setFoodObject({...foodObject, hour_min: e.target.value})}
                                 /> 
                          
                        
                            {/* form for food */}
                            <div className="FoodPage-form-div-width">
                                <br />
                                
                                <button type="submit" className="btn FoodPage-hover FoodPage-scan-barcode">Scan Barcode</button>
                                <br />
                                <br />
                                <h3>Food Name:</h3>
                                <input
                                    type="text"
                                    className="form-control"
                                    rows="1"
                                    value={foodObject.food_name}
                                    onChange={e => setFoodObject((prevState) => ({...prevState, food_name: e.target.value}))}
                                ></input>
                                <br />
                                <h3>Description (optional):</h3>
                                <input
                                    type="text"
                                    className="form-control"
                                    rows="2"
                                    value={foodObject.description}
                                    onChange={e => setFoodObject((prevState) => ({...prevState, description: e.target.value}))}
                                ></input>
                                {Object.entries(errorMessages).map(([key, error]) => (<span key={`${key}: ${error}`} style={{fontWeight: "bold"}}>{error}<br /></span>))}

                                <br />
                                <button type="button" className="btn FoodPage-hover FoodPage-add" onClick={postFoodUpdate} disabled={foodFormState.isSubmitting}>
                                    Add
                                </button>
                            </div>
                        </div>


                        <div className="col-lg-6 FoodPage-history-right">
                            {/* food history data */}
                            <div>
                                <h2 className="FoodPage-center">Food History</h2>
                                <div className="FoodPage-history">
                                    {!foodFormState.isLoading &&
                                    foodHistory.map((entry, index) => (
                                        <div key={index} className="FoodPage-food-in-list">
                                            <div >
                                                <span className="justify-content-between">
                                                    <p className="FoodPage-history-left"><b>{entry.food_name}</b></p>
                                                    <p className="text-end">{entry.time_period.slice(11, 16)} <i class="bi bi-trash"></i></p>
                                                </span>
                                                </div>
                                                {entry.description ? 
                                                    <div>
                                                        <hr />
                                                        <p className="me-2">{entry.description.substring(0,175)}</p>
                                                    </div>
                                                    
                                                : null} 
                                        </div>
                                    ))
                                    }
                                    {foodFormState.isLoading &&
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    }
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {selectedOption === "Water" && (
                <div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div>
                            {watType === false && (
                                <div className="p-3">
                                    <h5>Cups</h5>
                                    <input
                                        className="form-control"
                                        rows="1"
                                        value={waterCups}
                                        onChange={(e) => setWaterCups(e.target.value)}
                                        type="number"
                                    ></input>
                                    <button className="btn btn-secondary mt-2" style={{width: "100%"}} onClick={inverseWat}>Switch to Milliliters</button>
                                </div>
                            )}
                            {watType === true && (
                                <div className="p-3">
                                    <h5>Milliliters</h5>
                                    <input
                                    className="form-control"
                                    rows="1"
                                    value={waterLiters}
                                    onChange={(e) => setWaterLiters(e.target.value)}
                                    type="number"
                                ></input>
                                <button className="btn btn-secondary mt-2" style={{width: "100%"}} onClick={inverseWat}>Switch to Cups</button>
                                </div>
                            )}
                            </div>          
                            
                            {Object.entries(errorMessages).map(([key, error]) => (<span key={`${key}: ${error}`} style={{fontWeight: "bold"}}>{error}<br /></span>))}

                            <br />
                            <button type="button" className="btn FoodPage-hover FoodPage-add" onClick={handleAddWater}>
                                Add
                            </button>

                        </div>


                        <div className="col-lg-6 FoodPage-history-right">

                            <div>
                                <h2 className="FoodPage-center">Water History</h2>
                                <div className="FoodPage-history">
                                   
                                    {/* {waterHistory.map((entry, index) => (
                                        <div key={index} className="FoodPage-food-in-list">
                                            <div >
                                                <span className="justify-content-between">
                                                    <p className="FoodPage-history-left"><b>{entry.quantity} ml</b></p>
                                                    <p className="text-end"><i class="bi bi-trash"></i></p>
                                                </span>
                                            </div>
                                    </div> */}
                                    <div className="FoodPage-food-in-list">
                                            <div >
                                                <span className="justify-content-between">
                                                    <p className="FoodPage-history-left"><b>{waterHistory.reduce((array, value) =>  array = array + value.quantity , 0)}</b></p>
                                                    <p className="text-end">ml</p>
                                                    <p className="FoodPage-history-left">Water intake today</p>
                                                </span>
                                            </div>
                                    </div> 
                                    <div className="FoodPage-food-in-list">
                                            <div >
                                                <span className="justify-content-between">
                                                    <p className="FoodPage-history-left"><b>2000</b></p>
                                                    <p className="text-end">ml</p>
                                                    <p>Your target</p>
                                                    {waterHistory.reduce((array, value) =>  array = array + value.quantity , 0) > 2000 && <p className="FoodPage-history-left">Congratulations, you met your target!</p>}
                                                </span>
                                            </div>
                                    </div> 
                    
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default FoodPageRadioButtonFoodWater;
