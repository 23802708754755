import { BehaviorSubject } from 'rxjs';
import Role from '../_helpers/role';
import api from "./api";
import tokenService from "./token.service";

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('previousAuthTokenResponse')));

export const authenticationService = {
    login,
    logout,
    register,
    updateToken,
    isAdmin,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return tokenService.getPreviousAuthTokenResponse() }
};


function isAdmin(){
  console.log(tokenService.getRole())
  return tokenService.getRole() === Role.Admin
}
async function login(email, password) {
  return  await api
    .post("/auth/login", {
      email,
      password
    })
    .then(response => {
      if (response.data.accessToken) {
        const data = response.data;
        tokenService.saveApiTokenResponse(data);
       
        currentUserSubject.next(data);
      }
      return response.data;
    });
}

function updateToken(token){
  currentUserSubject.next(token);
}


function logout() {
  tokenService.removeUser();
  currentUserSubject.next(null);
}

async function register(firstName, lastName, emailAddress,password) {
return await api.post( "auth/signup", {
 firstName:  firstName, lastName: lastName, email: emailAddress, password : password
}).then(response => {
  if(response.data){
    const data = response.data;
    tokenService.saveApiTokenResponse(data);
    
    currentUserSubject.next(data);
  }
  else{
    console.log("Error occured, no data returned when registering")
  }
  console.log(response.data);
  return response.data;
});
}

export default authenticationService;
