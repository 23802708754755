import {Button, InputGroup, Form} from "react-bootstrap";
import React, {useState} from 'react';



export default function RegisterQues() {
  async function nextButton(e) {
    if (selectedOption > 5){
        // do submission
        window.location.replace('/account');
    } else{
        setSelectedOption(selectedOption + 1)
    }
    
    
  }

  async function prevButton(e) {
    if (selectedOption > 1){
        setSelectedOption(selectedOption - 1)
    }    
  }

  async function skipSurvey(e) {
    window.location.replace('/account')
  }

 

  const handleOptionChange = (event) => {
    const errorMessages = {}
    setErrors(errorMessages)
    console.log(event.target.value)
    setSelectedOption(parseInt(event.target.value));
    };

  const handleStrgChg = (event) => {
    setSChg(parseInt(event.target.value));
  }

  const handlePurpose = (event) => {
    setPurpose(parseInt(event.target.value));
  }

  const drink = (event) => {
    setdoDrink(parseInt(event.target.value))
  }

  const relationship = (event) => {
    setdoRelationship(parseInt(event.target.value))
  }

  const majorChange = (event) => {
    setdomajorChange(parseInt(event.target.value))
  }
  
  const [selectedOption, setSelectedOption] = useState(1);
  const [SChg, setSChg] = useState("");
  const [purpose, setPurpose] = useState("");
  const [doDrink, setdoDrink] = useState(0);
  const [doRelationship, setdoRelationship] = useState(0);
  const [domajorChange, setdomajorChange] = useState(0);

  const [errorMessages, setErrors] = useState({});


   return (
    <div className="container-fluid">
        <div className="row">
            <div className="col-lg-2"></div>
                <div className="col-sm-12 col-lg-8">
                  <div> 
                      <div className="container-fluid accountpage-div">
                            <div className="row">
                                <div className="text-center pt-5">
                                    <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" onChange={handleOptionChange} checked={selectedOption ===1} name="inlineRadioOptions" id="screen1" value="1"/>
                                    </div>
                                    <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" onChange={handleOptionChange} checked={selectedOption ===2} name="inlineRadioOptions" id="screen2" value="2" />
                                    </div>
                                    <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" onChange={handleOptionChange} checked={selectedOption ===3} name="inlineRadioOptions" id="screen3" value="3" />
                                    </div>
                                    <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" onChange={handleOptionChange} checked={selectedOption ===4} name="inlineRadioOptions" id="screen4" value="4" />
                                    </div>
                                    <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" onChange={handleOptionChange} checked={selectedOption ===5} name="inlineRadioOptions" id="screen5" value="5" />
                                    </div>
                                    <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" onChange={handleOptionChange} checked={selectedOption ===6} name="inlineRadioOptions" id="screen6" value="6" />
                                    </div>
                                </div>
                            </div>
                          <div className="row">
                              <div className="col-lg-6 order-lg-first order-last col-sm-12 p-5 pb-3 pt-xl-5 pt-2  ">
                              <h2>Welcome to Healthify</h2>
                              <p>An app to improve your well-being by managing symptoms of chronic conditions</p>
                              {/* <h3>Who's behind Healthify?</h3>
                              <div>
                                <img src={process.env.PUBLIC_URL + 'monika_holding_food.webp'} style={{width:"25%"}} className={"float-start"} alt="A photo of Dr Monika Gostic holding food."></img>
                                <p className={"float-end"}>Dr. Monika Gostic</p>
                              </div> */}
                              <h3>Should I be aware of anything?</h3>
                              <p>Nutrition is a complex subject, and the content on the app is not a replacement for medical advice, which you should always get from your GP. Consult with your GP if you have any concerns about the suitability of this programme for you.</p>
                              <h3>How is data security handeled on the app?</h3>
                              <p>The app is designed with multiple safeguards to keep your data safe.</p>
                              <hr />
                              <h5>Don't want to finish the survey?</h5>
                              <p>You can return to it at any time.</p>
                              <Button style={{width: "100%"}} variant="primary" type="submit" value="REGISTER" onClick={skipSurvey}>Skip</Button> 

                              </    div>
                              <div className="col-lg-6 order-lg-last order-first col-sm-12 p-1 p-lg-5 pt-0 pt-xl-5 pb-3 ">
                                <div className="p-2">
                                {selectedOption === 1 && (
                                    <div>
                                        <h3>Introduction Questions</h3>
                                        <div className="p-2">
                                            <h5>How old are you?</h5>
                                            <Form.Control type="number" name="age" placeholder="Years"/>
                                        </div>
                                        
                                        <div className="p-2">
                                            <h5>What is the most important health area you want to improve in the next 60 days?</h5>
                                            <Form.Select aria-label="Purpose" onChange={handlePurpose}>
                                            <option value="1">Chronic Illness</option>
                                            <option value="2">Autoimmune Disease</option>
                                            <option value="3">Food Intolerance</option>
                                            <option value="4">Weight Loss</option>
                                            <option value="5">Other</option>
                                            </Form.Select>
                                            { purpose ===5 && (
                                                <div>
                                                    <p className="pt-1 pb-0 mb-1 mt-1">Please specify</p>
                                                    <Form.Control type="text" name="purposeSpecific"/>
                                                </div>
                                                
                                            )}
                                        </div>

                                        <div className="p-2">
                                            <h5>How Long Have You Been Struggling?</h5>
                                            <Form.Select aria-label="Length" onChange={handleStrgChg}>
                                            <option value="1">Less than a year</option>
                                            <option value="2">1-5 years</option>
                                            <option value="3">Throughout adulthood</option>
                                            <option value="4">I was born with it</option>
                                            <option value="5">Other</option>
                                            </Form.Select>  
                                            { SChg ===5 && (
                                                <div>
                                                    <p className="pt-1 pb-0 mb-1 mt-1">Please specify</p>
                                                    <Form.Control type="text" name="struggleLength"/>
                                                </div>
                                                
                                            )}    
                                        </div>               
                                    </div>
                                    
                                )}

                                {selectedOption ===2 && (
                                    <div>
                                        <h3>Medicine History</h3>
                                        <div className="p-2">
                                            <h5>Are you on any medication?</h5>
                                            <p>If so, please specify.</p>
                                            <Form.Control as="textarea" rows={2} />
                                        </div>
                                        <div className="p-2">
                                            <h5>Have you tried any solutions before?</h5>
                                            <p>Includes supplements.</p>
                                            <Form.Control as="textarea" rows={2} />
                                        </div>
                                        <div className="p-2">
                                            <h5>Why do you think you have failed?</h5>
                                            <Form.Control as="textarea" rows={2} />
                                        </div>
                                    </div>
                                )}

                                {selectedOption ===3 && ( 
                                    <div>
                                        <h3>Physical Health</h3>
                                        <div className="p-2">
                                            <h5>How do you feel about your physical health?</h5>
                                            <p>Including weight, immune system and energy level.</p>
                                            <Form.Control as="textarea" rows={2} />
                                        </div>
                                        <div className="p-2">
                                            <h5>Describe your physical fitness, welness and exercise habits.</h5>
                                            <Form.Control as="textarea" rows={2} />
                                        </div>
                                        <div className="p-2"> 
                                            <h5>How are your sleep habits?</h5>
                                            <Form.Control as="textarea" rows={2} />
                                        </div>
                                        <div className="p-2">
                                            <h5>How well do you feel that you are in-tune with your body?</h5>
                                            <Form.Control as="textarea" rows={2} />
                                        </div >
                                    </div>
                                )}
                                
                                {selectedOption ===4 && ( 
                                    <div>
                                        <h3>Lifestyle Questions</h3>
                                        <div className="p-2">
                                            <h5>Do you smoke, drink alcohol, take drugs (or have done so in the past)?</h5>
                                            <InputGroup style={{width: "100%"}}>
                                                <Button style={{width: "50%"}} checked={doDrink ===0} value="0" onClick={drink}>No</Button> 
                                                <Button style={{width: "50%"}} checked={doDrink ===1} value="1" onClick={drink}>Yes</Button> 
                                            </InputGroup>
                                            {doDrink ===1 && (
                                                <div>
                                                    <p className="pt-1 pb-0 mb-1 mt-1">Please Specify</p>
                                                    <Form.Control type="text" name="drinkDeets"/>
                                                </div>
                                            )}
                                        </div>
                                        <div className="p-2">
                                            <h5>As an adult, have you ever worked in a one-on-one relationship?</h5>
                                            <InputGroup style={{width: "100%"}}>
                                                <Button style={{width: "50%"}} checked={relationship ===0} value="0" onClick={relationship}>No</Button> 
                                                <Button style={{width: "50%"}} checked={relationship ===1} value="1" onClick={relationship}>Yes</Button> 
                                            </InputGroup>
                                            {doRelationship ===1 && (
                                                <div>
                                                    <p className="pt-1 pb-0 mb-1 mt-1">Please Specify</p>
                                                    <Form.Control type="text" name="drinkDeets"/>
                                                </div>
                                            )}
                                        </div>
                                        <div className="p-2">
                                            <h5>Have you experienced any major changes in the past two years?</h5>
                                            <p>For example, a new job, new house, divorce etc</p>
                                            <InputGroup style={{width: "100%"}}>
                                                <Button style={{width: "50%"}} checked={majorChange ===0} value="0" onClick={majorChange}>No</Button> 
                                                <Button style={{width: "50%"}} checked={majorChange ===1} value="1" onClick={majorChange}>Yes</Button> 
                                            </InputGroup>
                                            {domajorChange ===1 && (
                                                <div>
                                                    <p className="pt-1 pb-0 mb-1 mt-1">Please Specify</p>
                                                    <Form.Control type="text" name="drinkDeets"/>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {selectedOption ===5 && ( 
                                    <div>
                                        <h3>Stress Questions</h3>
                                        <div className="p-2">
                                            <h5>On a scale of 1 to 10, how much stress is in your life right now?</h5>
                                            <Form.Label className="float-start">1</Form.Label>
                                            <Form.Label className="float-end">10</Form.Label>
                                            <Form.Range />
                                        </div>
                                        <div className="p-2">
                                            <h5>What causes you stress?</h5>
                                            <Form.Control as="textarea" rows={2} />
                                        </div>
                                        <div className="p-2">
                                            <h5>What are you tolerating in your life at present? </h5>
                                            <p>For example, clutter, bills, dissatisfaction etc</p>
                                            <Form.Control as="textarea" rows={2} />
                                        </div>
                                        <div className="p-2">
                                            <h5>On a scale of 1 to 10, what is your quality of life right now?</h5>
                                            <Form.Label className="float-start">1</Form.Label>
                                            <Form.Label className="float-end">10</Form.Label>
                                            <Form.Range />
                                        </div>
                                    </div>
                                )}

                                {selectedOption ===6 && ( 
                                    <div>
                                        <h3>Goals of Healhify</h3>
                                        <div className="p-2">
                                            <h5>What are the three areas you wish to develop, enhance, or grow?</h5>
                                            <Form.Control as="textarea" rows={1} />
                                        </div>
                                        <div className="p-2">
                                            <h5>What is it specifically that you want to achieve?</h5>
                                            <Form.Control as="textarea" rows={2} />
                                        </div>
                                        <div className="p-2">
                                            <h5>Why do you want to achieve this goal?</h5>
                                            <p>For example, clutter, bills, dissatisfaction etc</p>
                                            <Form.Control as="textarea" rows={2} />
                                        </div>
                                        <div className="p-2">
                                            <h5>What is the deeper meaning or personal significance that this goal has for you?</h5>
                                            <Form.Control as="textarea" rows={1} />
                                        </div>
                                        <div className="p-2">
                                            <h5>How you will know if you've attained your desired outcome?</h5>
                                            <Form.Control as="textarea" rows={1} />
                                        </div>
                                    </div>
                                )}

                                
                                   
                                    {(selectedOption !== 1 && selectedOption !== 6) && (
                                        <div>
                                        <InputGroup style={{width: "100%"}}>
                                        <Button style={{width: "50%"}} variant="secondary" type="submit" value="REGISTER" onClick={prevButton}>Previous</Button> 
                                        <Button style={{width: "50%"}} variant="primary" type="submit" value="REGISTER" onClick={nextButton}>Next</Button> 
                                        </InputGroup>

                                        </div>
                                    )}
                                    {selectedOption ===1 && (
                                        <Button style={{width: "100%"}} variant="primary" type="submit" value="REGISTER" onClick={nextButton}>Next</Button> 
                                    )}

                                    {selectedOption ===6 && (
                                        <Button style={{width: "100%"}} variant="primary" type="submit" value="REGISTER" onClick={nextButton}>Finish</Button> 
                                    )}

                                  {Object.entries(errorMessages).map(([key, error]) => (<span key={`${key}: ${error}`} style={{fontWeight: "bold"}}>{error}<br /></span>))}
                              </div>
                      </div>
                    </div>
                  </div>
                <div className="col-lg-2"></div>
              </div>
            <div>
          </div>
        </div>
      </div>
    </div>
  );  
}  
 