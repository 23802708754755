import { Routes, Route, Navigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import "react-bootstrap";
import Layout from "./navigation_components/Layout";
import Home from "./navigation_components/Home";
import FoodPage from "./navigation_components/FoodPage";
import ShareMood from "./navigation_components/ShareMood";
import MoodSleep from "./navigation_components/MoodSleep";
import AccountHome from "./navigation_components/AccountHome";
import AccountSettings from "./navigation_components/AccountSettings";
import AccountRedeem from "./navigation_components/AccountRedeem";
import AccountRedeemTemplate from "./navigation_components/AccountRedeemTemplate";
import AccountRedeemSuccess from "./navigation_components/AccountRedeemSuccess";
import TaskTemplate from "./navigation_components/TaskTemplate";
import TaskSuccessful from "./navigation_components/TaskSuccessful";
import NoMatch from "./navigation_components/NoMatch";
import CommunityHome from "./navigation_components/CommunityHome";
import CommunityExample from "./navigation_components/CommunityExample";
import Contact from "./navigation_components/Contact";
import Terms from "./navigation_components/Terms";
import Admin from "./navigation_components/Admin";
import Login from "./navigation_components/Login";
import AdminCreateTask from "./navigation_components/AdminCreateTask";
import AdminTaskSuccess from "./navigation_components/AdminTaskSuccess";
import Authentication from "./navigation_components/Authentication";
import Registration from "./navigation_components/Registration";
import RegistrationQues from "./navigation_components/RegistrationQuestions";


import authenticationService from "./services/authentication.service";
import { DarkModeProvider } from "./navigation_components/DarkModeContext";


export default function App() {
  return (
    <DarkModeProvider> 
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="terms" element={<Terms />} />
          <Route path="contact" element={<Contact />} />
          <Route path="community" element={<CommunityHome />} />
          <Route path="community/example" element={<CommunityExample />} />
          <Route path="login" element={authenticationService.currentUserValue ?  <Navigate to="/account" />: <Login />} />
          <Route path="register" element={authenticationService.currentUserValue ?  <Navigate to="/account" />: <Registration />} />
          <Route path="*" element={<NoMatch />} />

          <Route path="account/" element={authenticationService.currentUserValue ? authenticationService.isAdmin() ? <Navigate to="/admin " /> : <Authentication /> : <Navigate to="/login"/>}>
            <Route index element={<AccountHome />}/>
            <Route path="survey" element={<RegistrationQues />} />
            <Route path="redeem" element={<AccountRedeem />} />
            <Route path="redeem/selection" element={<AccountRedeemTemplate />} />
            <Route path="redeem/success" element={<AccountRedeemSuccess />} />
            <Route path="task" element={<TaskTemplate />} />
            <Route path="task/success" element={<TaskSuccessful />} />
            <Route path="settings" element={<AccountSettings />} />

            <Route path="tracking/">
              <Route index element={<Navigate to="../../account" />} />
              <Route path="food" element={<FoodPage />} />
              <Route path="mood/share-mood" element={<ShareMood />} />
              <Route path="mood" element={<MoodSleep />} />
            </Route>

            
          </Route>
        </Route>

        <Route path="admin/" element={authenticationService.currentUserValue ? authenticationService.isAdmin() ? <Authentication /> : <Navigate to="/account" /> : <Navigate to="/login" />}>
          <Route index element={<Admin />} />
          <Route path="create-task" element={<AdminCreateTask />} />
          <Route path="create-task/success" element={<AdminTaskSuccess />} />
        </Route>
      </Routes>
    </DarkModeProvider>
  );
};
