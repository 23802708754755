import React, { useState } from "react";
import { Link } from "react-router-dom";
import MoodModal from "./ShareMood";
import SleepModal from "./SleepModal";
import SleepGraph from "./SleepGraph";

export default function MoodSleep() {
  const [isMoodModalOpen, setMoodModalOpen] = useState(false);
  const [isSleepModalOpen, setSleepModalOpen] = useState(false);
  const [sleepData, setSleepData] = useState([]);
  const [error, setError] = useState("");
  const [weekOffset, setWeekOffset] = useState(0); // State to track which week is currently displayed

  const handleSleepSubmit = (hours, date) => {
    const formattedDate = new Date(date).toLocaleDateString();

    setSleepData((prevData) => {
      const dateEntry = prevData.find((entry) => entry.date === formattedDate);
      const updatedHours = dateEntry ? dateEntry.hours + hours : hours;

      if (updatedHours > 24) {
        setError(`Total sleep for ${formattedDate} cannot exceed 24 hours.`);
        return prevData;
      }

      const newData = dateEntry
        ? prevData.map((entry) =>
            entry.date === formattedDate ? { ...entry, hours: updatedHours } : entry
          )
        : [...prevData, { date: formattedDate, hours: updatedHours }];

      setError("");
      return newData;
    });
  };

  const getCurrentWeekDates = () => {
    const today = new Date();
    today.setDate(today.getDate() + weekOffset * 7);
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay() + 1); // Set to Monday of the current week

    const weekDates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(startOfWeek);
      date.setDate(startOfWeek.getDate() + i);
      weekDates.push(date.toLocaleDateString());
    }

    return weekDates;
  };

  const getWeekSleepData = () => {
    const weekDates = getCurrentWeekDates();
    return sleepData.filter((entry) => weekDates.includes(entry.date));
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-sm-12 col-lg-8 p-5 pb-3 pt-lg-5 pt-2">
          <h2><Link to="../../"><button className="btn btn-secondary">←</button></Link> Mood and Sleep Tracking</h2>
          <div className="MoodPage-div-width">
            <button
              className="share-mood-button"
              onClick={() => setMoodModalOpen(true)}
            >
              Share your today's mood!
            </button>
            <MoodModal isOpen={isMoodModalOpen} onClose={() => setMoodModalOpen(false)} />
          </div>

          <div className="MoodPage-div-width">
            <button
              className="sleep-button"
              onClick={() => setSleepModalOpen(true)}
            >
              How much did you sleep?
            </button>
            <SleepModal
              isOpen={isSleepModalOpen}
              onClose={() => setSleepModalOpen(false)}
              onSleepSubmit={handleSleepSubmit}
            />
          </div>

          {error && <p style={{ color: "red" }}>{error}</p>}

          {/* Display the sleep graph for the selected week with navigation buttons */}
          <SleepGraph
            sleepData={getWeekSleepData()}
            weekDates={getCurrentWeekDates()}
            onPreviousWeek={() => setWeekOffset((prev) => prev - 1)}
            onNextWeek={() => setWeekOffset((prev) => prev + 1)}
          />
        </div>
        <div className="col-lg-2"></div>
      </div>
    </div>
  );
}
