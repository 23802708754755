import { useLocation, useNavigate } from "react-router-dom";
import "../index.css"; 

export default function AccountRedeemSuccess() {
  const location = useLocation();
  const navigate = useNavigate();
  const awardData = location.state;
  const points = 100;


  if (!awardData) {
    navigate('/account/redeem');
    return null;
  }


  const handleReturnClick = () => {
    navigate('/account/redeem');
  };

  const handleClaimClick = () => {

    navigate('/account/redeem');
  };


  if (awardData.value > points) {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 p-5 pb-0 pt-lg-5 pt-2">
            <h2>You don't have enough points to redeem {awardData.title}</h2>
            <h2>You need {awardData.value - points} more!</h2>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-2"></div>
          <div className="col-sm-12 col-lg-8 p-5 pb-3 pt-0">
            <button
              onClick={handleReturnClick}
              className="taskclaimpoints rounded custom-button"
            >
              Return
            </button>
          </div>
        </div>
      </div>
    );
  } else {

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 p-5 pb-0 pt-lg-5 pt-2">
            <h2>{awardData.title} - Redeemed successfully!</h2>
            <h2>Your new balance is {points - awardData.value} points.</h2>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-2"></div>
          <div className="col-sm-12 col-lg-8 p-5 pb-3 pt-0">
            <button
              onClick={handleClaimClick}
              className="taskclaimpoints rounded custom-button"
            >
              Claim
            </button>
          </div>
        </div>
      </div>
    );
  }
}
