import React from 'react';
import { Outlet, Link } from "react-router-dom";
import { Nav, Navbar, Container, NavDropdown, Offcanvas } from "react-bootstrap";
import authenticationService from "../services/authentication.service";
import ErrorBoundary from "../components/ErrorBoundary";
import "../index.css"; 
import { useDarkMode } from "../navigation_components/DarkModeContext"; 

export default function Layout() {
  const { darkMode } = useDarkMode(); 

  function logoutButton(e) {
    e.preventDefault();
    authenticationService.logout();
    window.location.replace('/');
  }

  function loginNavbar() {
    if (authenticationService.currentUserValue) {
      return (
        <Nav className="justify-content-end flex-grow-1 p-lg-3 p-0 ps-3">
          <hr />
          <NavDropdown title="Account" id="offcanvasNavbarDropdown" className={"bigHeader"}>
            <Link to="/account/settings" style={{textDecoration: "none"}}>
              <NavDropdown.Item href="/account/settings">Settings</NavDropdown.Item>
            </Link>            
            <NavDropdown.Item onClick={logoutButton}>Logout</NavDropdown.Item>
          </NavDropdown>

          <Link to="/account/settings" style={{textDecoration: "none"}}>
            <Nav.Link href="/account/settings" className={"smallHeader"}>Account Settings</Nav.Link>
          </Link>
          <Nav.Link onClick={logoutButton} className={"smallHeader"}>Logout</Nav.Link>
          <Link to="account" className={"p-0 mt-2 ps-2 ps-lg-0"}>
            <button className="btn btn-success">Home</button>
          </Link>
        </Nav>
      );
    } else {
      return (
        <Nav className="justify-content-end flex-grow-1 p-lg-3 p-0 ps-3">
          <hr />
          <Link to="login" className={"p-0 mt-2 ps-2 ps-lg-0"}>
            <button className="btn btn-primary">Sign In</button>
          </Link>
        </Nav>
      );
    }
  }

  return (
    <div className={darkMode ? "dark-mode" : ""}>
      <Navbar className={`headerStyle ${darkMode ? "navbar-dark-mode" : ""}`} expand={"lg"} collapseOnSelect>
        <Container fluid>
          <div className={"col-lg-2 justify-content-lg-end justify-content-start"}>
            <Link to="/" style={{textDecoration: "none"}}>
              <Navbar.Brand className={"headerName smallHeader mt-lg-1"}>Healthify</Navbar.Brand>
            </Link>
            
          </div>
          <div className={"col-lg-8"}>
            <Navbar.Offcanvas
              id="navbarSlideButton"
              aria-labelledby="navigationSlide"
              placement="end"
            >
              <Offcanvas.Body>
                <Nav className="justify-content-end justify-content-lg-start flex-grow-1 p-lg-3 p-0">
                  <Offcanvas.Header closeButton>
                    <Link to="/" style={{textDecoration: "none"}}>
                      <Navbar.Brand className={"headerName"}>Healthify</Navbar.Brand>
                    </Link>
                  </Offcanvas.Header>
                  <Link to="/" style={{textDecoration: "none"}}>
                      <Navbar className={"headerName bigHeader"}>Healthify</Navbar>
                  </Link>
                  <Link to="/community" style={{textDecoration: "none"}}>
                      <Navbar className={"ms-3 pt-0 mt-0 p-lg-2 nav-link"}>Community</Navbar>
                  </Link>
                </Nav>
                {loginNavbar()}
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </div>
          <div className="col-lg-2 d-flex justify-content-end align-items-center">
            <Navbar.Toggle aria-controls="navbarSlideButton" className={"mr-auto"} />
          </div>
        </Container>
      </Navbar>

      <div className={"mainContent"}>
        <ErrorBoundary fallback={
          <div className="container-fluid justify-content">
            <div className="col-sm-12 col-lg-8" style={{ "margin": "auto", "padding-top": "10px" }}>
              <div className="alert alert-danger" role="alert">
                An error occurred while trying to process this page. <Link reloadDocument to={"/"} className="alert-link">Go home?</Link>
              </div>
            </div>
          </div>
        }>
          <Outlet />
        </ErrorBoundary>
      </div>

      <footer className={`container-fluid footerStyle ${darkMode ? "footer-dark-mode" : ""}`}>
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-sm-6 col-lg-4 p-2 pt-2 pt-lg-4">
            <p className="text-white text-start m-0">This app is not a replacement for medical advice. Consult with your GP if you have any concerns about the suitability of this programme for you.</p>
          </div>
          <div className="col-sm-6 col-lg-4 p-2 pt-0 pt-lg-2">
            <Link to="contact" style={{ textDecoration: `none` }}>
              <p className="text-white text-lg-end text-start m-0">Contact</p>
            </Link>
            <Link to="terms" style={{ textDecoration: `none` }}>
              <p className="text-white text-lg-end text-start m-0">Terms</p>
            </Link>
            <Link to="https://monikagostic.com/" style={{ textDecoration: `none` }}>
            <p className="text-white text-lg-end text-start m-0">Dr Monika Gostic Ltd <i className="bi bi-box-arrow-up-right"></i></p>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-sm-12 col-lg-8 p-2 pt-0 m-0">
            <hr />
            <div className="text-center" >
              <small className="text-white m-0"><i className="bi bi-c-circle-fill"></i> Copyright 2024/25<br /></small>
              <small className="text-white m-0">Team Delta for Dr Monika Gostic<br /></small>
              <small className="text-white m-0">A CS3028 Project</small>
            </div>
            
          </div>
          <div className="col-lg-2"></div>
        </div>
      </footer>

    </div>
  );
}
