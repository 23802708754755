import React, { useState } from "react";

export default function SleepModal({ isOpen, onClose, onSleepSubmit }) {
  const [sleepAmount, setSleepAmount] = useState("");
  const [error, setError] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]); // Default to today's date

  const handleSubmit = (event) => {
    event.preventDefault();

    if (sleepAmount < 0) {
      setError("The amount of sleep can't be negative!");
      return;
    }

    if (sleepAmount > 24) {
      setError("Total sleep cannot exceed 24 hours.");
      return;
    }

    onSleepSubmit(Number(sleepAmount), date); // Pass both sleep amount and date
    setSleepAmount("");
    setDate(new Date().toISOString().split("T")[0]); // Reset to today's date
    setError("");
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button onClick={onClose} className="modal-close">X</button>
        <form onSubmit={handleSubmit}>
          <label>
            Date:
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
              className="modal-input"
            />
          </label>
          <label>
            Please enter the amount of sleep you had:
            <input
              type="number"
              placeholder="Ex. 8"
              value={sleepAmount}
              onChange={(e) => {
                setSleepAmount(e.target.value);
                setError("");
              }}
              className="modal-input"
              required
            />
          </label>
          {error && <p style={{ color: "red", fontSize: "14px" }}>{error}</p>}
          <button type="submit" className="modal-done-button mt-3">Done</button>
        </form>
      </div>
    </div>
  );
}
