// import { Link } from "react-router-dom";

export default function Terms() {
  return (
    <div>
      <div className="container-fluid">
          <div className="row">
              <div className="col-lg-2"></div>
                  <div className="col-sm-12 col-lg-8 p-5 pb-3 pt-lg-5 pt-2">
                    <h1 data-testid="my-header">Terms and Conditions</h1>
                    <p>Please read these terms of service ("terms of service", "terms") carefully before using the Healthify website (“website”, "service") operated by Dr. Monika Gostic Ltd ("us", 'we", "our").</p>
                    <h5>Conditions of Use</h5>
                    <p>By using this website, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to leave the website accordingly. Healthify only grants use and access of this website, its products, and its services to those who have accepted its terms.</p>
                    <h5>Age restriction</h5>
                    <p>You must be at least 18 (eighteen) years of age before you can use this website. By using this website, you warrant that you are at least 18 years of age and you may legally adhere to this Agreement. We assume no responsibility for liabilities related to age misrepresentation.</p>
                    <h5>Intellectual Property</h5>
                    <p>You agree that all materials, products, and services provided on this website are the property of us, its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute our intellectual property in any way, including electronic, digital, or new trademark registrations. You grant us a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and publish. For issues regarding intellectual property claims, you should contact the company in order to come to an agreement.</p>
                    <h5>User Accounts</h5>
                    <p>As a user of this website, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password. If you think there are any possible issues regarding the security of your account on the website, inform us immediately so we may address it accordingly. We reserve all rights to terminate accounts, edit or remove content in their sole discretion.</p>
                    <h5>Applicable Law</h5>
                    <p>By visiting this website, you agree that the laws of the United Kingdom, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between us and you, or its business partners and associates.</p>
                    <h5>Disputes</h5>
                    <p>Any dispute related in any way to your visit to this website or to products you purchase from us shall be arbitrated by court and you consent to exclusive jurisdiction and venue of such courts.</p>
                    <h5>Indemnification</h5>
                    <p>You agree to indemnify us and our affiliates and hold us harmless against legal claims and demands that may arise from your use or misuse of our services. We reserve the right to select our own legal counsel.</p>
                    <h5>Limitation on liability</h5>
                    <p>We are not liable for any damages that may occur to you as a result of your misuse of our website.</p>
                    <p>Dr Monika Gostic Ltd. reserves the right to edit, modify, and change this Agreement any time. We shall let our users know of these changes through electronic mail. This Agreement is an understanding between [name] and the user, and this supersedes and replaces all prior agreements regarding the use of this website.</p>
                    <hr />
                    <h1 id="privacy">Privacy Policy</h1>
                    <p>At Healthify, we only request the minimum amount of data required from our users.</p>
                    <p>These are:</p>
                    <ul>
                      <li>Registration Information (Including but not limited to: Name, E-Mail, DOB) - visible to Dr Gostic for communication</li>
                      <li>Tracking Information (Food, Sleep) - visible to Dr Gostic for analysis</li>
                      <li>Rewards Information (Redemptions) - visible to Dr Gostic and select third parties depending on the redeemed award</li>
                      <li>Community forum posts - visible to everyone</li>
                    </ul>
                    <p>We also keep a log of when users last logged in and their IP addresses, to reduce spam and more correctly identify users.</p>
                    <p>If you have any concerns or requests regarding your data, please contact us.</p>
                  </div>
             </div>
      </div>
    </div>
  );
}
