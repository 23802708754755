import React from 'react';
import { useDarkMode } from "./DarkModeContext"; 
import { Link } from 'react-router-dom'
import tokenService from "../services/token.service";


export default function AccountSettings() {
  const { darkMode, toggleDarkMode } = useDarkMode(); 

  return (
    <div className="container-fluid">
        <div className="row align-items-start">
          <div className="col-lg-2">

          </div>
          <div className="col-lg-8 col-sm-12 p-5 pb-3 pt-lg-5 pt-2">
            <h2><Link to="../"><button className="btn btn-secondary">←</button></Link> Account Settings</h2>
          </div>
          <div className="col-lg-2">
            
          </div>
          <div className="col-lg-2">
            
          </div>
          <div className="col-lg-4 col-sm-12 p-5 pb-3 pt-lg-5 pt-2">
              <div>
                <h3>Display Preferences</h3>
              </div>
                <div style={{ marginTop: '20px'}}>
                <span style={{ fontWeight: 'bold', marginRight: '10px' }}>{darkMode ? "Dark Mode" : "Light Mode"}</span>
                <label className="switch float-end">
                  <input type="checkbox" checked={darkMode} onChange={toggleDarkMode} />
                  <span className="slider round"></span>
                </label>
                </div>
                <div style={{ marginTop: '20px'}}>
                <span style={{ fontWeight: 'bold', marginRight: '10px' }}>Increased Contrast</span>
                <label className="switch float-end">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                </div>
                <div style={{ marginTop: '20px'}}>
                <span style={{ fontWeight: 'bold', marginRight: '10px' }}>Bold Text</span>
                <label className="switch float-end">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                </div>
                <hr />
                <h3>Update Personal Details</h3>
                <div className={"pt-2 pb-2"}>
                  <div className={"mb-2"}>
                    <label for="fname" className="form-label">First Name</label>
                    <input className="form-control" id="fname" type="text" placeholder={tokenService.getFirstName()} disabled />
                  </div>
                  <div className={"mb-2"}>
                    <label for="fname" className="form-label">First Name</label>
                    <input className="form-control" id="fname" type="text" placeholder={tokenService.getLastName()} disabled />
                  </div>
                  <div className={"mb-2"}>
                    <label for="fname" className="form-label">Email</label>
                    <input className="form-control" id="email" type="email" placeholder="placeholder email" disabled />
                  </div>
                  <button className={"settings-button"} style={{width: "100%"}}>Update</button>
                </div>
            </div>
          
          <div className="col-lg-4 col-sm-12 p-5 pb-3 pt-lg-5 pt-2">
              <div>
                <h3>More Account Settings</h3>
                <button className="settings-button">Change Password</button>
                <button className="settings-button">Accessibility</button>
                <button className="settings-button">Delete Account</button>
                <Link to="../survey"><button className="settings-button">Take Survey</button></Link>

                <hr />
                <h3>About Healthify</h3>  
                <Link to="../../terms"><button className="settings-button">Terms and Privacy</button></Link>
                <Link to="../../contact"><button className="settings-button">Contact Us</button></Link>
              </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
    
  );
}
