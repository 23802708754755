import React from "react";
import { Link } from "react-router-dom";
import FoodPageRadioButtonFoodWater from "./FoodPageRadioButtonFoodWater";

export default function FoodPage() {
    return (
      <div className="container-fluid">
        <div className="row align-items-start">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 p-5 pb-3 pt-lg-5 pt-2">
            <h2><Link to="../../"><button className="btn btn-secondary">←</button></Link> Food and Water</h2>
            <div className="row p-5 pb-3 pt-lg-5 pt-2">
              <div className="col-lg-6">
                <h3>
                  Get started by adding food below
                </h3>
                <p>You've logged food <b>4</b> items today! Keep up the good work.</p>
                <p>Why not check out our community curated recipies?</p>
              </div>
                <div className="col-lg-6 text-lg-end" >
                    <h3>Your overview</h3>
                    <div className={"bg-white rounded"}>
                      <div className={"p-2 foodpage-good"}>
                        <h5>You're eating a variety of food <i class="bi bi-check-circle-fill"></i></h5>
                      </div>
                      <div className={"p-2 foodpage-good"}>
                        <h5>You're drinking lots of water <i class="bi bi-check-circle-fill"></i></h5>
                      </div>
                      <div className={"p-2 foodpage-warn"}>
                        <h5>Watch your sugar levels <i class="bi bi-exclamation-circle-fill"></i></h5>
                      </div>
                      <div className={"p-2 foodpage-dang"}>
                        <h5>Reduce the your anti-inflamatory food intake <i class="bi bi-exclamation-triangle-fill"></i></h5>
                        <p><i>example of specific advice</i></p>
                      </div>
                      <div className={"p-2 foodpage-dang"}>
                        <h5>Eat less bananas <i class="bi bi-exclamation-triangle-fill"></i></h5>
                      </div>
                    </div> 
                </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-2"></div>
          <div className="col-lg-8 p-5 pb-3 pt-lg-5 pt-2">
            <hr />
            <h2>Food and Water Tracking</h2>
            {/* food/water radio */}
            <FoodPageRadioButtonFoodWater />

          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
    );
  }
  